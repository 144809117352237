import './src/styles/global.css';

import { QueryClient, QueryClientProvider } from 'react-query';
import { broadcastQueryClient } from 'react-query/broadcastQueryClient-experimental';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { ReactQueryDevtools } from 'react-query/devtools';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';

const queryClient = new QueryClient();

if (typeof window !== 'undefined') {
  const localStoragePersistor = createWebStoragePersistor({
    storage: window.localStorage,
  });

  persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
    dehydrateOptions: {
      shouldDehydrateQuery: (query) => {

        if (query.queryKey.startsWith('/cardcatalog/connectorstatus:')) return false; // don't persist connector status information

        // experimental: do not store/persist (= dehydrate) results with error code 

        if (!!query.state?.data?.ErrorCode) {
          //console.log("xxx",query.queryKey,query.state?.data?.ErrorCode, query.state.data.Data?.ErrorText )
          return false; // do not persist queries with ErrorCode
        }

        return true;
      }
    }
  });
}

broadcastQueryClient({
  queryClient,
  broadcastChannel: 'adenin-digital-assistant',
});

if (typeof document !== 'undefined' && typeof navigator !== 'undefined' && /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)) {
  document.onreadystatechange = () => {
    if (document.readyState != 'complete') return;
    document.documentElement.setAttribute('class', 'platform-apple');
  };
}

export const onClientEntry = () => {
  if (!sessionStorage.length) {
    console.log('Ask other tabs for session storage');
    localStorage.setItem('getSessionStorage', Date.now());
  };

  window.addEventListener('storage', function (event) {
    if (event.key == 'getSessionStorage') {
      console.log('Some tab asked for the sessionStorage -> send it');

      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      localStorage.removeItem('sessionStorage');
    }

    if (event.key == 'sessionStorage' && !sessionStorage.length) {
      console.log('sessionStorage is empty -> fill it');

      const data = JSON.parse(event.newValue);

      for (const key in data) {
        sessionStorage.setItem(key, data[key]);
      }
    }
  });

  // BroadcastChannel implementation, doesn't seem to work:

  /* const StorageEvents = {
    CHANNEL: 'session_storage_transfer',
    REQUEST: 'storage_request',
    TRANSFER: 'storage_transfer'
  };
  
  const channel = new BroadcastChannel(StorageEvents.CHANNEL);
  console.log('Subscribing to sessionStorage transfer');

  channel.onmessage = function (event) {
    if (event.type == StorageEvents.REQUEST) {
      console.log('Received sessionStorage request, sending any existing sessionStorage to channel');
      channel.postMessage({
        type: StorageEvents.TRANSFER,
        payload: sessionStorage.length ? JSON.stringify(sessionStorage) : null
      });
    }

    if (event.type == StorageEvents.TRANSFER) {
      console.log('Received sessionStorage transfer');

      if (!event.data.payload || sessionStorage.length) return;

      console.log('Existing sessionStorage is empty, adding transfered value');

      const receivedStorage = JSON.parse(event.data.payload);

      for (const key in receivedStorage) {
        sessionStorage.setItem(key, event.data[key]);
      }
    }
  };

  if (!sessionStorage.length) {
    console.log('No existing sessionStorage, requesting transfer');
    channel.postMessage({
      type: StorageEvents.REQUEST
    });
  } */
};

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    {element}
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
